import * as Sentry from '@sentry/nextjs';
import { v4 as uuidv4 } from 'uuid';

import { PUBLIC_EVENT_PATH } from '@hl-portals/constants';

const requestId = uuidv4();

export function usePublicEvent<Event>(source?: string) {
  async function recordPublicEvent(event: Event = {} as Event) {
    const payload = {
      source,
      request_id: requestId,
      ...event,
    };

    const res = { status: null } as { status: null | number };

    try {
      const response = await fetch(PUBLIC_EVENT_PATH, {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/json',
          'X-Request-Id': requestId,
        }),
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        res.status = response.status;
        throw new Error(
          `An unexpected Event error occurred with payload: ${payload}`
        );
      }
    } catch (error) {
      Sentry.captureException(new Error('Event error'), {
        extra: {
          endpoint: PUBLIC_EVENT_PATH,
          requestId,
          status: res.status,
          payload,
        },
      });
    }
  }

  return { recordPublicEvent };
}
