import { useEffect, useState } from 'react';

import { useRouter } from 'next/router';

import { Box, Button, Paragraph } from '@hl-portals/ui';
import { useUserEvents } from '@hl-portals/hooks';
import { enhancedAxios } from '@hl-portals/helpers';
import { AGENT_REFERRAL_AGREEMENT_ESIGN } from '@hl-portals/constants';
import { useModal } from '@hl-portals/hooks';
import { useUser } from '../../../contexts/Auth';
import { ImageWrapper } from './styles';

const DynamicAlert = ({ onDismiss, agentAgreementChanges, ...props }) => {
  const router = useRouter();
  const { closeModal } = useModal();
  const [trackingFired, setTrackingFired] = useState(false);
  const { recordUserEvent } = useUserEvents();
  const { user } = useUser();
  const image = props.image || '/illustrations/agreement.png';

  useEffect(() => {
    const trackLoad = async () => {
      const agreement = await enhancedAxios({
        url: AGENT_REFERRAL_AGREEMENT_ESIGN,
        translateJsona: false
      });
      const agreementVersion = agreement.agreement_id;

      recordUserEvent({
        source: 'agent_dashboard',
        event_action: 'agreement_restriction_view',
        includeSource: false,
        result: {
          platform: 'web',
          source: 'dashboard',
          treatment: 'modal',
          version_required: agreementVersion,
          agent_id: user.agent.id.toString(),
        },
      });
    }

    if (!trackingFired && agentAgreementChanges?.agreement_changes_status === 'restriction') {
      setTrackingFired(true);
      trackLoad();
    }
  }, [agentAgreementChanges, trackingFired, user]);

  return (
    <Box {...props} flexDirection="column">
      <ImageWrapper>
        <img src={image} />
      </ImageWrapper>

      <Paragraph variant="heading-3" textAlign={{ xs: 'center' }}>
        {agentAgreementChanges.modal_copy_title}
      </Paragraph>

      <Paragraph variant="text" mt="16px" textAlign={{ xs: 'center' }}>
        {agentAgreementChanges.modal_copy_body}
      </Paragraph>

      <Button
        size="large"
        mt="32px"
        onClick={() => {
          router.push('/settings/referral-agreement');
          closeModal();
        }}
      >
        Sign referral agreement
      </Button>

      <a
        href={agentAgreementChanges.resource_url}
        target="_blank"
        onClick={() => {
          closeModal();
        }}
      >
        <Button color="secondary" size="large" mt="16px" width="100%">
          Learn more
        </Button>
      </a>
    </Box>
  );
};

export default DynamicAlert;
