export const ACCEPT_ADDENDUM_PARAM_VALUE = 'acceptAddendum';
export const ACCEPT_ADDENDUM_URL_PARAM = `action=${ACCEPT_ADDENDUM_PARAM_VALUE}`;

export const TCPA_ADDENDUM_CHANGE_SUMMARY = [
  <span>Adherence to the Telephone Consumer Protection Act (TCPA)</span>,
  <span>Referral Fees to be charged on a transaction level for simplicity (i.e. on dual representation)</span>
];

export const TCPA_GRANDFATHERED_SUMMARY = [
  ...TCPA_ADDENDUM_CHANGE_SUMMARY,
  <span>Referral active period is updated from two to three years</span>
];

export const TCPA_ADDENDUM_TERMS = [
  <span>
    The definition of “Referral” will now be read as: From the Effective Date,
    HomeLight may refer transactions involving the potential purchase or potential sale of
    certain residential real estate properties to the Agent, by means of a HomeLight-operated portal,
    phone calls, text messages, emails, or other means (“<strong>Referral</strong>”).
  </span>,
  <span>
    The terms relating to “Dual Representation” have been replaced with Transaction-Based Referral Fee.
    I understand and acknowledge that the Referral Fee is calculated based upon the total Gross Commission
    earned on any Referral transaction which may include commissions earned from representing one or both parties
    to such underlying purchase and sale transaction. By way of example and for the avoidance of doubt,
    upon receiving a Referral for the sale of a property, if I initially only represent one party to such transaction
    but later come to represent both parties to such transaction and earn commission from both parties,
    I shall be obligated to pay the Referral Fee calculated using all commissions earned (from both parties)
    for the underlying Referral transaction.
  </span>,
  <span>
    <u>TCPA Compliance</u>: I hereby represent (on behalf of myself and my Broker) and agree that when contacting
    each Lead, I am in compliance with all relevant laws, and applicable governmental regulations,
    including without limitation, the CAN-SPAM Act of 2003, the Do Not Call Implementation Act, the
    Telemarketing and Client Fraud and Abuse Prevention Act, and the Telephone Consumer Protection Act,
    each as amended from time to time and will not be using automatic telephone dialing system, any
    artificial or prerecorded voice, or “voice cloning” software to simulate a human voice). I further agree
    to indemnify and hold HomeLight harmless for any breach of this representation as further described
    in&nbsp;<a href="https://www.homelight.com/terms">HomeLight’s Terms of Service</a>.
  </span>,
  <span>
    The definition of a “Pre-Existing-Relationship” has been changed to clarify that a Pre-Existing Relationship
    means any Client that had been actively working with the Agent in the 90-pay period immediately preceding
    the date of receiving any Referral transaction from HomeLight, subject to confirmation in accordance with
    Section 3(b) hereof.
  </span>,
  <span>
    Section 3(b) is amended further to read that in the event that the Agent receives a Referral that involves
    a Pre-Existing Relationship with any Referral transaction from HomeLight, the Agent shall promptly
    (not to exceed two (2) business days after such Referral) notify HomeLight and provide HomeLight
    with supporting documentation (including, without limitation, a listing agreement or other written
    form of the intent of Client to work with such Agent) of the Pre-Existing Relationship.
    If the Agent fails to notify HomeLight or provide proper evidence, the Agent shall be responsible
    for payment of the Referral Fee subject to the Referral.
  </span>,
  <span>
    The following language has been added towards the end of the “Miscellaneous” Section:
    except as it relates to the terms set forth in HomeLight’s Terms of Service which are incorporated
    by reference herein, which may also be updated by HomeLight in its sole discretion from time to time.
  </span>
];

export const TCPA_GRANDFATHERED_TERMS = [
  <span>
    The definition of “Referral” will now be read as:
    <br />
    <br />
    HomeLight may refer residential real estate properties and/or transactions involving the potential purchase
    or potential sale of certain residential real estate properties to the Agent, by means of a HomeLight-operated
    portal, phone calls, text messages, emails, or other means (“<strong>Referral</strong>”).
  </span>,
  <>
    <span>
      Terms under the Section titled “Referral Fee” have been deleted and replaced with the terms below:
    </span>
    <ul>
      <li>
        Referral Fee is triggered when a transaction closes within the period commencing on the date of
        the most recent Referral and ending on the date that is three (3) years after such Referral date
        (the “Active Period”).
      </li>
      <li>
        The referral fee shall be 25% of Agent's total Gross Commission (as defined below) earned in connection
        with the Referral transaction (the “Referral Fee”). “Gross Commission” is the amount, including
        any and all bonuses, earned by Agent’s broker prior to any split of commission with Agent or
        any other brokers on Agent's side of the transaction, including commission earned by representing
        one or both sides of a Referral transaction. For the avoidance of doubt, upon receiving a Referral
        for the purchase or sale of a property, if Agent initially only represents one party to such transaction
        but later comes to represent both parties to such transaction and earns commission from both parties,
        the Referral Fee due and payable by Agent to HomeLight would be based upon the commissions earned
        from both parties in such transaction.
      </li>
      <li>
        “Pre-Existing Relationship” means any Client that had been actively working with Agent in the 90-pay period
        immediately preceding the date of receiving any Referral from HomeLight, as confirmed by HomeLight
        in accordance with the terms herein. In the event that Agent receives a Referral that involves a
        Pre-Existing Relationship, Agent shall promptly (not to exceed two (2) business days after such Referral)
        notify HomeLight by providing supporting documentation of such relationship (including, without limitation,
        a listing agreement, buyer representation agreement or other written form of the intent of Client to
        work with Agent). If Agent fails to notify HomeLight or provide proper evidence, Agent shall be responsible
        for payment of the full Referral Fee for such transaction.
      </li>
      <li>
        Upon a Referral properly made to Agent, if Agent acts in the capacity of an investor and purchases
        the property from the Client, in place of the Referral Fee, Agent shall pay to HomeLight an amount
        that is equal to four percent (4%) of the purchase price of such Referral property (“Investor Fee”).
      </li>
      <li>
        If multiple transactions result from a single Referral such that a Referral (of such underlying property)
        leads to additional transactions for Agent (whether in Agent’s capacity as a real estate agent or
        as an investor), all such transactions that are initiated during the Active Period shall be subject to
        the Referral Fee (or Investor Fee, as applicable) and Agent agrees to pay HomeLight upon or prior to
        the closing of each such transaction so long as such transaction was initiated within the Active Period.
      </li>
      <li>
        After the signing of a contract for a transaction resulting from a Referral, Agent will promptly notify
        HomeLight via email or the HomeLight portal and indicate the expected closing date. Agent will have
        the resulting Referral Fee paid to HomeLight from escrow or by its broker no later than ten (10) days of
        closing, and will include supporting documentation such as the HUD-1, ALTA, or applicable closing disclosure.
      </li>
    </ul>
  </>,
  <span>
    <u>TCPA Compliance</u>: Agent hereby represents (on behalf of Agent and its broker) and agrees that when
    contacting each Client, Agent is in compliance with all relevant laws, and applicable governmental regulations,
    including without limitation, the CAN-SPAM Act of 2003, the Do Not Call Implementation Act, the
    Telemarketing and Client Fraud and Abuse Prevention Act, and the Telephone Consumer Protection Act,
    each as amended from time to time and will not be using automatic telephone dialing system, any artificial
    or prerecorded voice, or “voice cloning” software to simulate a human voice). Agent further agrees to
    indemnify and hold HomeLight harmless for any breach of this representation as further described
    in&nbsp;<a href="https://www.homelight.com/terms">HomeLight’s Terms of Service</a>&nbsp;as set forth
    in www.homelight.com/terms.
  </span>,
  <span>
    The Agreement will now be governed under Arizona Law without regard to its conflict of law provisions.
    You consent to the exclusive jurisdiction of, and venue in, the state and federal courts of the State of Arizona.
  </span>,
  <span>
    A new “Miscellaneous” Section has been added towards the end of the Referral Agreement and shall be read as:
    Agent hereby represents to the Agent Representations and Warranties, and agrees to abide by HomeLight’s
    conditions and obligations with respect to Confidentiality, Indemnification, Limitations on Liability, Legal Fees,
    Severability, and No Waiver of Rights, each of which can be found under the respective sub-headers in
    HomeLight’s Terms of Service (<a href="https://www.homelight.com/terms">www.homelight.com/terms</a>),
    and each is hereby incorporated by reference with the same force and effect as though fully set forth herein.
    This Agreement represents the entire agreement between the parties and may only be amended in a writing
    to be signed by both parties except as it relates to the terms set forth in HomeLight’s Terms of Service
    which are incorporated by reference herein, which may also be updated by HomeLight in its
    sole discretion from time to time.
  </span>
];
