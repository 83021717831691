import * as Sentry from '@sentry/nextjs';
import { AxiosRequestHeaders } from 'axios';
import dayjs from 'dayjs';
import { useFlags } from 'flagsmith/react';

import React, { createContext, useContext, useState } from 'react';

import { http } from '@hl-portals/libs/http';

import { config } from '@hl-portals/helpers';

import {
  fetchPresignedLink,
  processAndCreateFiles,
  tempProcessAndCreateDocuments,
  uploadFileToS3,
} from '../services/FileUploadServices';
import {
  IFileUploadContext,
  ISelectedFile,
  IUploadAndProcessFiles,
  IUploadAndProcessFilesReturn,
  ProcessedAwsDocumentFile,
  ProcessedAwsDocumentFileData,
  ProcessedAwsFileResponse,
  ProcessedAwsImageFile,
  TempUploadDocuments,
  UploadAndProcessFilesReturn,
  UploadedFilesReturn,
} from '../types';

const FileUploadContext = createContext<IFileUploadContext | undefined>(
  undefined
);

const batchList = (arr: Array<any>, size = 5) => {
  const chunks = [];

  for (let i = 0; i < arr.length; i += size) {
    const chunk = arr.slice(i, i + size);
    chunks.push(chunk);
  }

  return chunks;
};

const FileUploadProvider = ({
  children,
  initialFileList,
}: React.PropsWithChildren<{
  initialFileList?: Array<ISelectedFile>;
}>): React.ReactElement => {
  const {
    'new-task-functionality': { enabled: isIrContractTaskEnabled },
  } = useFlags(['new-task-functionality']);
  const [loading, setLoading] = useState<boolean>(false);
  const [percentProgress, setPercentProgress] = useState<number>(0);
  const [selectedFiles, setSelectedFiles] = useState<Array<ISelectedFile>>(
    initialFileList && initialFileList?.length > 0
      ? initialFileList?.map((item) => ({ ...item, isFileUploaded: true }))
      : []
  );

  const uploadAndProcessFiles = async ({
    fileList,
    ignoreProcessAndCreateStep,
    fileType,
    leadId,
    fileCategory,
    token,
    source,
    documentType,
    resourceId,
    resourceType,
  }: IUploadAndProcessFiles): Promise<IUploadAndProcessFilesReturn> => {
    const results: Array<UploadAndProcessFilesReturn> = [];
    const successUploadedFiles: Array<UploadedFilesReturn> = [];
    const failedFiles: Array<UploadedFilesReturn> = [];

    setLoading(true);
    setPercentProgress(0);

    try {
      // if (!leadId) {
      //   throw new Error(
      //     `Lead ID is required for this process and was not provided! Lead ID: ${leadId}`
      //   );
      // }

      let progress = 0;
      const totalSteps = ignoreProcessAndCreateStep ? 2 : 3;
      const progressTotal = fileList.length * totalSteps;

      for (const file of fileList) {
        if (file?.isFileUploaded) {
          continue;
        }
        /**
         * STEP 1: FETCH PRESIGNED LINK FROM S3
         */
        const presignedResponse = await fetchPresignedLink(
          file,
          fileType,
          source,
          leadId,
          documentType,
          resourceId,
          resourceType
        );
        setPercentProgress(++progress / progressTotal);

        const { url, key } = presignedResponse[0];
        if (!url || !key)
          failedFiles.push({
            name: null,
            key: null,
            fail: true,
            failReason: 'Failed to upload file',
          });

        /**
         * STEP 2: UPLOAD FILE TO S3
         */
        await uploadFileToS3({ file, url });
        setPercentProgress(++progress / progressTotal);

        successUploadedFiles.push({
          name: file.name,
          key,
          fail: false,
        });
      }

      /**
       * STEP 2.5: PDF MERGE
       */
      if (isIrContractTaskEnabled && fileType === 'pdf_merge') {
        const PDF_MERGE_URL = config.pdfMergerUrl;
        const PDF_MERGE_URL_API_KEY = config.pdfMergeApiKey;

        const headers: AxiosRequestHeaders = {
          'Content-Type': 'application/json',
          'x-api-key': PDF_MERGE_URL_API_KEY,
        };

        try {
          await http.public.post(
            PDF_MERGE_URL,
            {
              output_name: 'ir_contract',
              s3Keys: successUploadedFiles.map((file) => file.key),
            },
            {
              headers,
            }
          );
        } catch (error) {
          if (
            (error as { response?: { status: number } }).response?.status ===
            400
          ) {
            throw new Error(
              'Error - Documents are security protected. Please remove security or make an unprotected copy and try again.'
            );
          }
        }
      }

      /**
       * STEP 3: PROCESS AND CREATE FILE IN HAPI
       * (can be skipped by passing the 'ignoreProcessAndCreateStep' prop)
       */
      const uploadDateString = dayjs().toISOString();
      if (!ignoreProcessAndCreateStep) {
        const batchSuccessUploadedFiles = batchList(successUploadedFiles);
        const processedFileResponses = await Promise.all(
          batchSuccessUploadedFiles.map((files) =>
            processAndCreateFiles({
              files: files,
              leadId,
              fileCategory,
              fileType,
              token,
            })
          )
        );
        const processedFileResponse =
          processedFileResponses.reduce<ProcessedAwsFileResponse>(
            (result, response) => {
              if (![200, 201].includes(response._httpStatus))
                throw new Error('Failed on process and create request!');

              result.data = [...result.data, ...response.data];
              result._httpStatus = response._httpStatus;
              result.included = response.included;

              return result;
            },
            { data: [], _httpStatus: -1 }
          );

        setPercentProgress(++progress / progressTotal);

        /**
         * FORMAT RETURNED FILE OBJECT
         */
        const processedFiles = (processedFileResponse as ProcessedAwsImageFile)
          ?.data;
        const processedFileVersions = (
          processedFileResponse as ProcessedAwsImageFile
        )?.included;

        for (let index = 0; index <= processedFiles.length - 1; index++) {
          results.push({
            id: processedFiles[index]?.id,
            fileName: processedFiles[index]?.attributes?.name,
            uploadDateString,
            ...(fileType === 'image'
              ? {
                  thumbnail: `https://${processedFileVersions[index]?.attributes?.fastly_url}`,
                }
              : {}),
            storageKey: processedFileVersions[index]?.attributes?.storage_key,
          });
        }
      }

      setLoading(false);
      setSelectedFiles((prev) =>
        prev.map((item) => ({
          ...item,
          id: item.id,
          thumbnail: item.thumbnail,
          name: item.name,
          size: item.size,
          isFileUploaded: true,
        }))
      );
      return {
        success: true,
        results,
      };
    } catch (err) {
      Sentry.captureException(err);
      setLoading(false);
      console.error(err);
      return {
        success: false,
        results,
        errorMessage: String(err),
      };
    }
  };

  const tempUploadDocuments = async ({
    fileList,
    ignoreProcessAndCreateStep,
    fileType,
  }: IUploadAndProcessFiles): Promise<TempUploadDocuments> => {
    setLoading(true);
    setPercentProgress(0);

    try {
      let progress = 0;
      const totalSteps = ignoreProcessAndCreateStep ? 2 : 3;
      const progressTotal = fileList.length * totalSteps;
      const result = await Promise.all(
        fileList
          .filter((file) => !file.isFileUploaded)
          .map(async (file) => {
            /**
             * STEP 1: FETCH PRESIGNED LINK FROM S3
             */
            const presignedResponse = await fetchPresignedLink(file, fileType);
            setPercentProgress(++progress / progressTotal);

            const { url, key } = presignedResponse[0];
            if (!url || !key) return;

            /**
             * STEP 2: UPLOAD FILE TO S3
             */
            await uploadFileToS3({ file, url });
            setPercentProgress(++progress / progressTotal);

            /**
             * STEP 3: PROCESS AND CREATE FILE IN HAPI
             * (can be skipped by passing the 'ignoreProcessAndCreateStep' prop)
             */
            const uploadDateString = dayjs().toISOString();
            if (ignoreProcessAndCreateStep) {
              return { id: key, fileName: file.name, uploadDateString };
            }
            const processedFileResponse = await tempProcessAndCreateDocuments(
              key,
              file.name
            );

            setPercentProgress(++progress / progressTotal);
            if (![200, 201].includes(processedFileResponse._httpStatus)) return;

            const data = (processedFileResponse as ProcessedAwsDocumentFile)
              .data as
              | ProcessedAwsDocumentFileData
              | Array<ProcessedAwsDocumentFileData>;

            return {
              id: (data as ProcessedAwsDocumentFileData).id,
              fileName: file.name,
              uploadDateString,
            };
          })
      );

      setLoading(false);
      return {
        success: true,
        // @ts-ignore
        results: result,
      };
    } catch (err) {
      Sentry.captureException(err);
      setLoading(false);
      return {
        success: false,
        results: [],
        errorMessage: String(err),
      };
    }
  };

  return (
    <FileUploadContext.Provider
      value={{
        loading,
        setLoading,
        percentProgress,
        setPercentProgress,
        uploadAndProcessFiles,
        tempUploadDocuments,
        selectedFiles,
        setSelectedFiles,
      }}
    >
      {children}
    </FileUploadContext.Provider>
  );
};

const useFileUpload = () => {
  const context = useContext(FileUploadContext);

  if (!context) {
    throw new Error('useFileUpload must be used within a FileUploadProvider.');
  }

  return context;
};

export { FileUploadProvider, useFileUpload };
