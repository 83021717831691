import { useEffect, useState } from 'react';

export const StylesAwait = ({ children }: { children: React.ReactNode }) => {
  const [isStylesApplied, setIsStylesApplied] = useState(false);

  useEffect(() => {
    // BROWSER RENDERING SEQUENCE:

    // 1. Initial State:
    // - Component mounts
    // - Styles are being processed
    // - DOM is updating

    requestAnimationFrame(() => {
      // 2. First Frame:
      // - Browser has processed DOM updates
      // - Styles are being calculated
      // - Layout is being computed

      requestAnimationFrame(() => {
        // 3. Second Frame:
        // - Styles have been calculated
        // - Layout has been computed
        // - Visual updates are complete
        // - Safe to show content (i.e.to Hotjar)
        setIsStylesApplied(true);
      });
    });
  }, []);

  if (!isStylesApplied) return null;
  return <>{children}</>;
};
