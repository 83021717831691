import styled from 'styled-components';

import { Paragraph } from '@hl-portals/ui';

import { FileType } from './types';

const Bold = styled.span`
  font-weight: bold;
`;

export const FileTypeErrorMessage = (supportedFileTypes: FileType[] = []) => {
  return (
    <Paragraph color="#CA0D02">
      Invalid file type. Please upload a valid format{' '}
      {supportedFileTypes.map((a, i) => (
        <>
          <Bold key={a}>.{a}</Bold>
          {i < supportedFileTypes.length - 1 ? ', ' : ''}
        </>
      ))}
    </Paragraph>
  );
};
