import styled from 'styled-components';

import { Box, Button, Icon, Paragraph } from '@hl-portals/ui';
import { media } from '@hl-portals/helpers';

const ConfirmDismiss = ({ onConfirm, close }) => (
  <Box flexDirection="column">
    <WarningIconWrapper>
      <IconCircle>
        <Icon type="exclamationCircleLight" size={20} />
      </IconCircle>
    </WarningIconWrapper>

    <ConfirmTitle variant="heading-3">
      Are you sure you want to exit the updated Referral Agreement terms?
    </ConfirmTitle>

    <Paragraph variant="text" mt="16px">
      Starting 1/27/25, you will not be able to receive new referrals until you review and agree to the updated terms.
    </Paragraph>

    <ButtonSection>
      <Button
        size="large"
        color="urgent"
        onClick={onConfirm}
      >
        Yes, I'm sure
      </Button>

      <StyledOutlineButton
        variant="outlined"
        color="tertiary"
        size="large"
        onClick={close}
      >
        Cancel
      </StyledOutlineButton>
    </ButtonSection>
  </Box>
);

// styles //

const WarningIconWrapper = styled.div`
  display: none;
  ${media.desktop`
    display: block;
    margin-top: 2rem;
  `}
`;

const IconCircle = styled(Box)`
  ${({ theme }) => `
    align-items: center;
    justify-content: center;
    width: 52px;
    height: 52px;
    background-color: ${theme.colors.red100};
    border-radius: 50%;
  `}
`;

const ConfirmTitle = styled(Paragraph)`
  margin-top: 2.5rem;
  ${media.desktop`
    margin-top: 0.5rem;
  `}
`;

const ButtonSection = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  margin-top: 2.25rem;

  ${media.desktop`
    flex-direction: row;
  `}
  
  button {
    flex: 1;
  }
`;

const StyledOutlineButton = styled(Button)`
  ${({ theme }) => `
    &:hover {
      border: 1px solid ${theme.colors.electricBlue};
    }
  `}
`;

export default ConfirmDismiss;
