import { http } from '@hl-portals/libs/http';

import {
  FETCH_PRESIGNED_S3_LINK,
  PROCESS_AND_CREATE_DOCUMENT,
  PROCESS_AND_CREATE_FILE,
} from '@hl-portals/constants';

import { enhancedAxios } from '@hl-portals/helpers';

import { IProcessAndCreateFiles, ProcessedAwsFileResponse } from '../types';

const fetchPresignedLink = async (
  file: File,
  fileType: string,
  source?: string,
  leadId?: string,
  documentType?: string,
  resourceId?: string,
  resourceType?: string
) => {
  const res = await http.public.post(FETCH_PRESIGNED_S3_LINK, {
    mime: file.type,
    filename: file.name,
    file_count: 1,
    file_type: fileType === 'image' ? 'media' : fileType,
    source: source,
    attachable_id: leadId,
    document_type: documentType,
    resource_id: resourceId,
    resource_type: resourceType,
  });

  const data = res.data || {};
  return { ...data, _httpStatus: res.status };
};

const uploadFileToS3 = ({ file, url }: { file: File; url: string }) =>
  fetch(url, {
    method: 'PUT',
    body: file,
  });

const processAndCreateFiles = ({
  files,
  leadId,
  fileCategory,
  fileType,
  token,
}: IProcessAndCreateFiles): Promise<ProcessedAwsFileResponse> => {
  const payload = {
    include: 'file_versions',
    fields: {
      file_version: 'mime,fastly_url,storage_key',
    },
    ...(leadId ? { attachable_id: leadId } : {}),
    ...(leadId ? { attachable_type: 'Lead' } : {}),
    ...(fileCategory ? { category: fileCategory } : {}),
    files: files?.map((file) => ({
      name: file.name,
      storage_key: file.key,
      file_type: fileType,
    })),
    ...(token
      ? {
          token,
        }
      : {}),
  };
  return enhancedAxios({
    url: PROCESS_AND_CREATE_FILE,
    method: 'POST',
    data: payload,
  });
};

// this temp functions is here because we need to update the points where the /documents endpoint is used.
const tempProcessAndCreateDocuments = (
  key: string,
  fileName: string
): Promise<ProcessedAwsFileResponse> => {
  return enhancedAxios({
    url: PROCESS_AND_CREATE_DOCUMENT,
    method: 'POST',
    data: { file_key: key, name: fileName },
  });
};

export {
  fetchPresignedLink,
  uploadFileToS3,
  processAndCreateFiles,
  tempProcessAndCreateDocuments,
};
