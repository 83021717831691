import { AxiosError } from 'axios';

import { UseQueryOptions, useQuery } from 'react-query';

import { http } from '@hl-portals/libs/http';

import { LOCATION_ELIGIBILITY_PATH } from '@hl-portals/constants';

import { handleErrors } from '@hl-portals/helpers';

export type UseLocationEligibilityCheckData = {
  is_eligible: boolean;
};

type UseLocationEligibilityOptions = UseQueryOptions<UseLocationEligibilityCheckData>;

export const UseLocationEligibilityQueryKey = 'LOCATION_ELIGIBILITY';

export const LOCATION_ELIGIBILITY_ERROR = (state: string, zipcode: string) =>
  `Unable to check eligibility for state: ${state} ${zipcode}`;

export const useLocationEligibilityCheck = (
  state: string,
  zipcode: string,
  options?: UseLocationEligibilityOptions
) =>
  useQuery<UseLocationEligibilityCheckData>(
    [UseLocationEligibilityQueryKey, state],
    async () => {
      const res = await http.public.get(LOCATION_ELIGIBILITY_PATH(zipcode, state));
      return res.data;
    },
    {
      ...options,
      onError: (...args) => {
        options?.onError?.(...args);
        handleErrors(args[0] as AxiosError);
      },
    }
  );
